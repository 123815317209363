<template>
    <layout>
        <div class="dashboard mt-5">
            <div class="justify-space-between align-center px-10 d-none d-lg-flex">
                <h1 class="pr-10 heading-2">My orders</h1>
                <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none" depressed :to="{name: 'account.quote'}">Start new quote</v-btn>
            </div>
            <div class="main mt-5 px-md-10">
                <v-card flat class="px-7" v-if="order">
                    <v-card-text>
                        <div class="mb-4">
                            <h2 class="text-28-60 my-4" v-if="finish">Your order is successfully completed</h2>
                            <p v-if="finish">Detailed inorderation has been sent to your email. <br/>Now you can track your shipment</p>
                            <div class="d-flex justify-space-between align-center">
                                <div class="">
                                    <router-link :to="{ name: 'account.track', params: {} }" class="mb-1">Track shipping</router-link>
                                    <div class="text-28-75">#{{order.id}}
                                        <img src="@/assets/icons/svg/shipping/ltl-dark.svg" width="30" alt="">
                                    </div>
                                </div>
                                <div class="mb-5 d-flex flex-column align-end">
                                    <span v-if="!order.status.is_active" class="badge py-2 px-4 shipped">Shipped</span>
                                    <span v-if="order.status.is_active" class="badge py-2 px-4 active">Active</span>
                                    <p>Ship date: {{dateFormat(order.items[0].date)}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <v-row>
                                <v-col cols="12" lg="6">
                                    <div class="text-16-60 blue-text">
                                        Pickup
                                    </div>
                                    <v-row>
                                        <v-col cols="12" lg="6">
                                            <div class="item">
                                                <div class="text-14-60">
                                                    City
                                                </div>
                                                <div class="text-14" v-html="getCity(order)"></div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" lg="6">
                                            <div class="item">
                                                <div class="text-14-60">
                                                    Requested loading date
                                                </div>
                                                <div class="text-14">
                                                    {{dateFormat(order.items[0].date)}}
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" lg="6">
                                            <div class="item">
                                                <div class="text-14-60">
                                                    Additional services
                                                </div>
                                                <div v-for="item in order.items[0].additional" class="text-14">
                                                    {{ additional_services[item].name }}
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col v-if="order.items[0].location_type" cols="12" lg="6">
                                            <div class="item">
                                                <div class="text-14-60">
                                                    Type of location
                                                </div>
                                                <div v-if="location_types[order.items[0].location_type]" class="text-14">
                                                    {{ location_types[order.items[0].location_type].name }}
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <div class="text-16-60 blue-text">
                                        Delivery
                                    </div>
                                    <v-row>
                                        <v-col cols="12" lg="6">
                                            <div class="item">
                                                <div class="text-14-60">
                                                    City
                                                </div>
                                                <div class="text-14" v-html="getCity(order, 'to')"></div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" lg="6">
                                            <div class="item">
                                                <div class="text-14-60">
                                                    Requested loading date
                                                </div>
                                                <div class="text-14">
                                                    {{dateFormat(order.items[0].date)}}
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" lg="6">
                                            <div class="item">
                                                <div class="text-14-60">
                                                    Additional services
                                                </div>
                                                <div v-for="item in order.items[1].additional" class="text-14">
                                                    {{ additional_services[item].name }}
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col v-if="order.items[1].location_type" cols="12" lg="6">
                                            <div class="item">
                                                <div class="text-14-60">
                                                    Type of location
                                                </div>
                                                <div v-if="location_types[order.items[1].location_type]" class="text-14">
                                                    {{ location_types[order.items[1].location_type].name }}
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col v-if="order.items[0]" cols="12" lg="6">
                                    <div class="text-16-60 blue-text">Items</div>
                                    <div v-for="(item, key) in filterItems(order)" class="my-4">
                                        <div class="text-14-60 mb-1" v-html="item.description"></div>
                                        <div class="text-14 mb-5">
                                            {{ item.quantity }} Crate, Total {{ item.quantity * item.weight}} lbs, Class {{item.class}}
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <div class="text-16-60 blue-text">Carrier</div>
                                    <div class="d-flex justify-space-between">
                                        <div class="my-4">
                                            <div class="text-14-60 mb-1">Type</div>
                                            <div class="text-14 mb-5">
                                                {{order.items[0].type}}
                                            </div>
                                        </div>
                                        <div class="my-4">
                                            <div class="text-14-60 mb-1">Carier</div>
                                            <div class="text-14 mb-5">
                                                Freight Crew Inc.
                                            </div>
                                        </div>
                                        <div class="my-4">
                                            <div class="text-14-60 mb-1">Estimated transit</div>
                                            <div class="text-14 mb-5">

                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="12" lg="7">
                                    <div class="borderedcardtotal pa-6">
                                        <div class="text-16-60 blue-text">Total</div>
                                        <div class="d-flex justify-space-between mt-5">
                                                <div class=""></div>
                                                <div class="">
                                                    <div class="text-14">
                                                        Total price
                                                    </div>
                                                    <div class="text-28-75">
                                                        $154{{order.init_rate}}
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>

                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from '@/components/layouts/AccountLayout.vue'

export default {
    middleware: 'auth',
    components: {
        Layout,
    },
    props: ['finish'],
    data: () => ({
        order: null,
        location_types: {
            1: {
                key: 1,
                name: 'Business',
                desc: 'Commercial buildings and areas',
            },
            2: {
                key: 2,
                name: 'Residential',
                desc: 'Home and home businesses'
            },
            3: {
                key: 3,
                name: 'Drop off at a carrier terminal',
                desc: 'Save money and skip the pickup services'
            }
        },
        additional_services: {
            1: {
                key: 1,
                only: 'all',
                name: 'Lift gate',
                desc: 'When the location doesn\'t have a loading dock and your shipment is too heavy to lift without assistance'
            },
            2: {
                key: 2,
                only: 'up',
                name: 'Pickup inside the location',
                desc: 'When the driver needs to move the shipment from somewhere other than directly behind the truck'
            },
            3: {
                key: 3,
                only: 'up',
                name: 'Limited access',
                desc: 'A non-residential location that may be difficult for a truck to access. i.e. farms, construction site, church, strip mall, school, etc.'
            },
            4: {
                key: 4,
                only: 'down',
                name: 'Delivery inside the location',
                desc: 'When the driver needs to move the shipment from somewhere other than directly behind the truck'
            },
            5: {
                key: 5,
                only: 'down',
                disabled: true,
                name: 'Appointment Fee',
                desc: 'When the carrier is required to contact final delivery location for specific appointment window'
            },
            6: {
                key: 6,
                only: 'down',
                name: 'Residential delivery',
                desc: 'When the location is a residential address'
            },
        },
    }),
    mounted(){
        this.getLoad(this.$route.params.id);
    },
    methods: {
        getLoad(id){
            this.axios.get(process.env.VUE_APP_API + 'loads/v1/load/' + id).then(response => {
                this.order = response.data;
            });
        },
        filterItems(model){
            let items = model.items.filter(function(item){
                return item.is_shipper == 1;
            })
            return items
        },
        getCity(model, type = 'from'){
            let warehouse = model.items.filter(function(item){
                if(type == 'from')
                    return item.is_shipper == 1;
                return item.is_shipper == 0;
            })

            if(warehouse[0])
                return warehouse[0].warehouse.state + ', ' + warehouse[0].warehouse.city;
            return 'not set'
        },
        dateFormat(toFormat = false, dayPlus = 0){
            var obj = new Date();
            if(toFormat)
                obj = new Date(toFormat);
            let month = obj.getMonth() + 1;
            let date = obj.getDate() + dayPlus;
            let year = obj.getFullYear();
            return month + '/' + date + '/' + year;
        },
    }
}
</script>
<style lang="scss">
.borderedcardtotal{
    border: 1px solid #CDE0FF;
    box-sizing: border-box;
    border-radius: 5px;
}
</style>
